import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  errorMessageParser,
  getResponseValidator,
  LocalStorageService,
  ShowError,
  ShowInfo,
  WINDOW_REF
} from '@ui/legacy-lib';
import { Customer, Invoice } from '@ui/shared/models';
import { INFRASTRUCTURE_CONFIG } from '@ui/legacy-lib';

import {
  deleteCustomerMutation,
  enableExternalPricingMutation
} from 'admin/+state/queries';
import { notificationConfig } from 'admin/config';

import { AdminUserType } from 'admin/models/enums/admin-user-type';
import {
  cancelInvoice,
  changeLandlordUserEnabled,
  getCustomerById,
  getCustomers,
  getInvoiceById,
  getInvoices,
  getUsers,
  userImpersonation,
  UsersListResult
} from '../queries/gql-queries';
import * as fromActions from './landlord.actions';

@Injectable()
export class LandlordEffects {
  private actions$ = inject(Actions);
  private apollo = inject(Apollo);
  private storageService = inject(LocalStorageService);
  private config = inject(INFRASTRUCTURE_CONFIG);
  private windowRef = inject(WINDOW_REF);

  private fetchInvoicesQueryRef: QueryRef<any>;

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FETCH_USERS),
      switchMap(({ data: filter }) =>
        this.apollo
          .query({
            query: getUsers,
            variables: {
              input: filter
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(result => {
              return new fromActions.FetchUsersSuccess(
                result.data.getUsers.nodes,
                result.data.getUsers.page
              );
            }),
            catchError(err =>
              of(
                new fromActions.FetchUsersFail(
                  err ? err.message : 'Unexpected error'
                )
              )
            )
          )
      )
    )
  );

  fetchCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchCustomers>(fromActions.FETCH_CUSTOMERS),
      switchMap(action =>
        this.apollo
          .query({
            query: getCustomers,
            variables: {
              input: {
                filter: {
                  size: action.data.size,
                  page: action.data.page
                }
              }
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(result => {
              return new fromActions.FetchCustomersSuccess(
                result.data.getCustomers.nodes,
                result.data.getCustomers.page
              );
            }),
            catchError(err =>
              of(
                new fromActions.FetchCustomersFail(
                  err ? err.message : 'Unexpected error'
                )
              )
            )
          )
      )
    )
  );

  fetchInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchInvoices>(fromActions.FETCH_INVOICES),
      tap(action => {
        this.fetchInvoicesQueryRef = this.apollo.watchQuery({
          query: getInvoices,
          variables: {
            input: {
              filter: {
                size: action.data.size,
                page: action.data.page,
                sort: 'created,desc'
              },
              customerId: action.data.customerId
            }
          },
          fetchPolicy: 'no-cache'
        });
      }),
      switchMap(() =>
        this.fetchInvoicesQueryRef.valueChanges.pipe(
          tap(getResponseValidator<UsersListResult>()),
          map(result => {
            return new fromActions.FetchInvoicesSuccess(
              result.data.getInvoices.nodes,
              result.data.getInvoices.page
            );
          }),
          catchError(err =>
            of(
              new fromActions.FetchInvoicesFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        )
      )
    )
  );

  changeEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CHANGE_USER_ENABLED),
      switchMap((action: fromActions.ChangeUserEnabled) =>
        this.apollo
          .mutate<UsersListResult>({
            mutation: changeLandlordUserEnabled,
            variables: {
              id: action.id,
              enabled: action.enabled
            }
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(result => {
              return new fromActions.ChangeUserEnabledSuccess(
                result.data.changeLandlordUserEnabled
              );
            }),
            catchError(err =>
              of(
                new fromActions.ChangeUserEnabledFail(
                  err ? err.message : 'Unexpected'
                )
              )
            )
          )
      )
    )
  );

  searchCustomerById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FETCH_CUSTOMER_BY_ID),
      switchMap((action: fromActions.FetchCustomerById) =>
        this.apollo
          .query<UsersListResult>({
            query: getCustomerById,
            variables: {
              id: action.id
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(result => {
              return new fromActions.FetchCustomerByIdSuccess([
                result.data.getCustomerById
              ] as Customer[]);
            }),
            catchError(err =>
              of(
                new fromActions.FetchCustomerByIdFail(
                  err ? err.message : 'Unexpected'
                )
              )
            )
          )
      )
    )
  );

  searchInvoiceById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FETCH_INVOICE_BY_ID),
      switchMap((action: fromActions.FetchInvoiceById) =>
        this.apollo
          .query<UsersListResult>({
            query: getInvoiceById,
            variables: {
              id: action.invoiceId
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(
              result =>
                new fromActions.FetchInvoiceByIdSuccess([
                  result.data.getInvoiceById
                ] as Invoice[])
            ),
            catchError(err =>
              of(
                new fromActions.FetchInvoiceByIdFail(
                  err ? err.message : 'Unexpected'
                )
              )
            )
          )
      )
    )
  );

  userImpersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.IMPERSONATE_USER),
      map((action: fromActions.ImpersonateUser) => action),
      switchMap(action =>
        this.apollo
          .mutate<UsersListResult>({
            mutation: userImpersonation,
            variables: {
              email: action.email,
              userType: AdminUserType.LANDLORD
            }
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(res => {
              const {
                access_token,
                session_state,
                realm,
                endpoint: baseUrl,
                client: clientId
              } = res.data.userImpersonation;
              this.windowRef.open(
                `${this.config.environment.landlord_app_url}/impersonate?token=${access_token}&session_state=${session_state}&realm=${realm}&baseUrl=${baseUrl}&clientId=${clientId}`
              );
              return new fromActions.ImpersonateUserSuccess();
            }),
            catchError(err => [
              new fromActions.ImpersonateUserFail(
                err ? err.message : 'Unexpected'
              ),
              new ShowError(notificationConfig.user.impersonate.error)
            ])
          )
      )
    )
  );

  cancelInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CancelInvoice>(fromActions.CANCEL_INVOICE),
      switchMap(({ invoiceId }) =>
        this.apollo
          .mutate({
            mutation: cancelInvoice,
            variables: { id: invoiceId },
            update: () => this.refetch()
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            mergeMap(() => [
              new fromActions.CancelInvoiceSuccess(),
              new ShowInfo(notificationConfig.invoice.cancel.success)
            ]),
            catchError(err => [
              new fromActions.CancelInvoiceFail(
                err ? err.message : 'Unexpected error'
              ),
              new ShowError(notificationConfig.invoice.cancel.error)
            ])
          )
      )
    )
  );

  deleteCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteCustomers>(fromActions.DELETE_CUSTOMER),
      switchMap(({ id }) =>
        this.apollo
          .mutate({
            mutation: deleteCustomerMutation,
            variables: { id }
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            mergeMap(() => [
              new fromActions.DeleteCustomersSuccess(),
              new fromActions.FetchCustomers({}),
              new ShowInfo(notificationConfig.customer.delete.success)
            ]),
            catchError(err => [
              new ShowError(notificationConfig.customer.delete.error),
              new fromActions.DeleteCustomersFail(err)
            ])
          )
      )
    )
  );

  enableExternalPricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.EnableExternalPricing>(
        fromActions.ENABLE_EXTERNAL_PRICING
      ),
      switchMap(({ id, enable }) =>
        this.apollo
          .mutate({
            mutation: enableExternalPricingMutation,
            variables: { id, enable }
          })
          .pipe(
            mergeMap(() => [
              new fromActions.EnableExternalPricingSuccess(id, enable),
              new ShowInfo(
                notificationConfig.customer.enableExternalPricing[
                  enable ? 'enableSuccess' : 'disableSuccess'
                ]
              )
            ]),
            catchError(err => [new ShowError(errorMessageParser(err))])
          )
      )
    )
  );

  private refetch() {
    if (!this.fetchInvoicesQueryRef) {
      return;
    }
    void this.fetchInvoicesQueryRef.refetch();
  }
}
