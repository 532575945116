import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Apollo, QueryRef } from 'apollo-angular';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  getResponseValidator,
  ShowError,
  ShowInfo,
  WINDOW_REF
} from '@ui/legacy-lib';
import { INFRASTRUCTURE_CONFIG } from '@ui/legacy-lib';

import { notificationConfig as notification } from 'admin/config';
import { AdminUserType } from 'admin/models/enums/admin-user-type';
import {
  activatePropertySearcher,
  deletePropertySearcher,
  getPropertySearcherUsers,
  userImpersonation,
  UsersListResult
} from '../queries/gql-queries';
import * as fromActions from './property-searcher.actions';

@Injectable()
export class PropertySearcherEffects {
  private actions$ = inject(Actions);
  private apollo = inject(Apollo);
  private config = inject(INFRASTRUCTURE_CONFIG);
  private windowRef = inject(WINDOW_REF);

  private propertySearcherQuery: QueryRef<UsersListResult>;

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchPropertySearcherUsers>(
        fromActions.FETCH_PROPERTY_SEARCHER_USERS
      ),
      switchMap(({ filter }) => {
        this.propertySearcherQuery = this.apollo.watchQuery<UsersListResult>({
          query: getPropertySearcherUsers,
          variables: {
            filter
          },
          fetchPolicy: 'no-cache'
        });
        return this.propertySearcherQuery.valueChanges.pipe(
          tap(getResponseValidator<UsersListResult>()),
          map(
            result =>
              new fromActions.FetchPropertySearcherUsersSuccess(
                result.data.getPropertySearcherUsers.nodes,
                result.data.getPropertySearcherUsers.page
              )
          ),
          catchError(err =>
            of(
              new fromActions.FetchPropertySearcherUsersFail(
                err ? err.message : 'Unexpected'
              )
            )
          )
        );
      })
    )
  );

  activatePropertySearcher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ACTIVATE_PROPERTY_SEARCHER),
      switchMap((action: fromActions.ActivatePropertySearcher) =>
        this.apollo
          .mutate<UsersListResult>({
            mutation: activatePropertySearcher,
            variables: {
              email: action.email
            }
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            mergeMap(() => [
              new fromActions.ActivatePropertySearcherSuccess(),
              new ShowInfo(notification.user.activate.success)
            ]),
            catchError(err => [
              new fromActions.ActivatePropertySearcherFail(
                err ? err.message : 'Unexpected'
              ),
              new ShowError(notification.user.activate.error)
            ])
          )
      )
    )
  );

  userImpersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.IMPERSONATE_PROPERTY_SEARCHER),
      map((action: fromActions.ImpersonatePropertySearcher) => action),
      switchMap(action =>
        this.apollo
          .mutate<UsersListResult>({
            mutation: userImpersonation,
            variables: {
              email: action.email,
              userType: AdminUserType.PROPERTY_SEARCHER
            }
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(res => {
              const { access_token, session_state } =
                res.data.userImpersonation;
              this.windowRef.open(
                `${this.config.environment.tenant_app_url}/impersonate?token=${access_token}&session_state=${session_state}`
              );
              return new fromActions.ImpersonatePropertySearcherSuccess();
            }),
            catchError(err => [
              new fromActions.ImpersonatePropertySearcherFail(
                err ? err.message : 'Unexpected'
              ),
              new ShowError(notification.user.impersonate.error)
            ])
          )
      )
    )
  );

  deletePropertySearcher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DELETE_PROPERTY_SEARCHER),
      switchMap((action: fromActions.DeletePropertySearcher) =>
        this.apollo
          .mutate<UsersListResult>({
            mutation: deletePropertySearcher,
            variables: {
              id: action.id
            },
            update: () => this.refetch()
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            mergeMap(() => [
              new fromActions.DeletePropertySearcherSuccess(),
              new ShowInfo(notification.user.delete.success)
            ]),
            catchError(err => [
              new fromActions.ActivatePropertySearcherFail(
                err ? err.message : 'Unexpected'
              ),
              new ShowError(notification.user.delete.error)
            ])
          )
      )
    )
  );

  private refetch() {
    if (!this.propertySearcherQuery) {
      return;
    }
    void this.propertySearcherQuery.refetch();
  }
}
